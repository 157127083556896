<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Add Item -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$bvModal.show('modal-create-prodotto')"
            >
              Aggiungi
            </b-button>

            <prodotti-list-create
              :type-options="typeOptions"
              @refetch-data="refetchData"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refProdottiListTable"
      class="position-relative mh-200"
      :items="fetchProdotti"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No items found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Code -->

      <template #cell(codice)="data">
        <div class="text-nowrap">
          <b-link @click="$bvModal.show('modal-edit-prodotto-'+data.item.id)">
            <span class="align-text-top text-capitalize">{{ data.item.code }}</span>
          </b-link>
        </div>
      </template>

      <template #cell(tipo)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
        </div>
      </template>

      <template #cell(nome)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
        </div>
      </template>

      <template #cell(descrizione)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.description }}</span>
        </div>
      </template>

      <template #cell(prezzo)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.price }}</span>
        </div>
      </template>

      <template #cell(v.p.c.)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(azioni)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <!--          <b-dropdown-item @click="data.toggleDetails">-->
          <!--            <feather-icon icon="EyeIcon" />-->
          <!--            <span-->
          <!--              class="align-middle ml-50"-->
          <!--            >Dettagli</span>-->
          <!--          </b-dropdown-item>-->

          <b-dropdown-item
            v-if="$can('update', 'Items')"
            @click="$bvModal.show('modal-edit-prodotto-'+data.item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span
              class="align-middle ml-50"
            >Modifica</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Items')"
            ref="swalConfirmDeleteIscritto"
            @click="swalConfirmDeleteIscritto(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Cancella</span>
          </b-dropdown-item>

          <prodotti-list-edit
            v-if="$can('update', 'Items')"
            :id="'modal-edit-prodotto-'+data.item.id"
            :item-edit-data="data.item"
            :type-options="typeOptions"
            @refetch-data="refetchData"
          />

        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInput,
  BButton,
  BLink,

  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useProdottiList from '@/views/prodotti/prodotti/useProdottiList'
import ProdottiListCreate from '@/views/prodotti/prodotti/prodotti-create/ProdottiListCreate.vue'
import ProdottiListEdit from '@/views/prodotti/prodotti/prodotti-edit/ProdottiListEdit.vue'

export default {
  name: 'ProdottiTable',
  components: {
    ProdottiListEdit,
    ProdottiListCreate,
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BButton,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  watch: {
  },
  mounted() {
  },
  setup() {
    const {
      // Options
      typeOptions,
      //
      fetchProdotti,
      refetchProdotto,
      getListProdotti,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProdottiListTable,
      refetchData,

      // Extra Filters
      //
    } = useProdottiList()

    return {
      // Options
      typeOptions,
      //
      fetchProdotti,
      refetchProdotto,
      getListProdotti,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProdottiListTable,
      refetchData,

      // Extra Filters
      //

    }
  },
  methods: {
    swalConfirmDeleteIscritto(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('items/forceDeleteProdotto', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Prodotto cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
