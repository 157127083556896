<template>
  <!-- modal -->
  <b-modal
    :id="'modal-create-prodotto'"
    :ref="'modal-create-prodotto'"
    :title="'Crea un nuovo prodotto'"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="xl"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="createProdottoForm">
      <form
        ref="form"
        class="repeater-form"
        @submit.stop.prevent="validationForm"
      >

        <b-row class="match-height">

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <b-form-group
                label="Tipo"
                label-for="type-input"
                invalid-feedback="Status is required"
              >
                <v-select
                  v-model="itemData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Nome"
                label-for="name-input"
              >
                <b-form-input
                  id="name-input"
                  v-model="itemData.name"
                  :state="getValidationState(validationContext)"
                  required
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="price"
              rules="required|integer"
            >
              <b-form-group
                label="Prezzo"
                label-for="name-input"
              >
                <b-input-group
                  prepend="€"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="price-input"
                    v-model="itemData.price"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="vpc"
              rules="required"
            >
              <b-form-group
                label="V.P.C."
                label-for="name-input"
              >
                <b-form-input
                  id="vpc-input"
                  v-model="itemData.vpc"
                  :state="getValidationState(validationContext)"
                  required
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="4"
            md="12"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="image"
              rules="required"
            >
              <b-form-group
                label="Immagine"
                label-for="image-input"
              >
                <b-form-file
                  v-model="itemData.image"
                  :state="getValidationState(validationContext)"
                  placeholder="Scegli immagine per il prodotto"
                  accept="image/jpeg, image/png"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="description"
              rules="required"
            >
              <b-form-group
                label="Descrizione"
                label-for="description-input"
                invalid-feedback="Obbligatorio"
              >
                <b-form-textarea
                  id="description-input"
                  v-model="itemData.description"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BFormInvalidFeedback, BFormTextarea, BInputGroup, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    vSelect,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormTextarea,
    BInputGroup,
    BFormFile,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {
    validationForm() {
      this.$refs.createProdottoForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.resetitemData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['modal-create-prodotto'].toggle('#toggle-btn')
      })
    },
  },
  setup(props, { emit }) {
    const blankItemData = {
      type: null,
      name: null,
      price: null,
      vpc: null,
      description: null,

      image: null,
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetitemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
    }

    const onSubmit = () => {
      const formData = new FormData()

      formData.append('type', itemData.value.type)
      formData.append('name', itemData.value.name)
      formData.append('price', itemData.value.price)
      formData.append('vpc', itemData.value.vpc)
      formData.append('description', itemData.value.description)
      formData.append('image', itemData.value.image)

      store.dispatch('items/addProdotto', formData)
        .then(() => {
          emit('refetch-data')
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetitemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetitemData,
    }
  },
}
</script>

<style lang="scss" scoped>
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
