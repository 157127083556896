import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refProdottiListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'codice', value: 'code', sortable: true },
    { key: 'tipo', value: 'type', sortable: true },
    { key: 'nome', value: 'name', sortable: true },
    { key: 'descrizione', value: 'description', sortable: true },
    { key: 'prezzo', value: 'price', sortable: true },
    { key: 'v.p.c.', value: 'vpc', sortable: true },
    { key: 'azioni' },
  ]

  const typeOptions = [
    { label: 'Caffè', value: 'caffe' },
    { label: 'Macchina', value: 'macchina' },
    { label: 'Altro', value: 'altro' },
  ]

  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('codice')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localUsersCount = refProdottiListTable.value ? refProdottiListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: totalUsers.value,
    }
  })

  const fetchProdotti = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('items/fetchProdotti', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sorterResolved,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { items, meta } = response.data.data
        if (callback) {
          callback(items)
        }
        totalUsers.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento dei prodotti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refProdottiListTable.value) {
      refProdottiListTable.value.refresh()
    } else {
      fetchProdotti()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchProdotto = () => {
    store.dispatch('items/fetchProdotto', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListProdotti = () => store.getters['items/getListProdotti']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    // Options
    typeOptions,
    //
    fetchProdotti,
    refetchProdotto,
    getListProdotti,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProdottiListTable,
    refetchData,

    // Extra Filters
    //

  }
}
