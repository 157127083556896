import { render, staticRenderFns } from "./ProdottiListCreate.vue?vue&type=template&id=32754d41&scoped=true&"
import script from "./ProdottiListCreate.vue?vue&type=script&lang=js&"
export * from "./ProdottiListCreate.vue?vue&type=script&lang=js&"
import style0 from "./ProdottiListCreate.vue?vue&type=style&index=0&id=32754d41&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32754d41",
  null
  
)

export default component.exports