<template>
  <div style="height: inherit">
    <prodotti-table />
  </div>
</template>

<script>
import useProdottiList from '@/views/prodotti/prodotti/useProdottiList'
import ProdottiTable from '@/views/prodotti/prodotti/prodotti-list/ProdottiTable.vue'

export default {
  name: 'Prodotti',
  components: { ProdottiTable },
  setup() {
    const {
      // Options
      //
      fetchProdotti,
      refetchProdotto,
      getListProdotti,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProdottiListTable,
      refetchData,

      // Extra Filters
      //
    } = useProdottiList()

    return {
      // Options
      //
      fetchProdotti,
      refetchProdotto,
      getListProdotti,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProdottiListTable,
      refetchData,

      // Extra Filters
      //

    }
  },
}
</script>

<style lang="scss">
  @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
  .item-view-radio-group ::v-deep {
    .btn {
      display: flex;
      align-items: center;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
